import React  , { useState }from 'react'

import ShowCard from '../ShowCards/ShowCard'

import { ApiCallGet } from '../ApiCall/ApiCall'
import ShowImages from '../ShowImages/ShowImages'

export default function DisplayCards( {myData , setNumFolio  , back , usuario }) {

  const [data,setData] = useState(myData)

  const [showTheImages,setShowTheImages] = useState( false )
  const [theIndex,setTheIndex] = useState(null)

  function onShowImages( index ) // Se no ssolicita mostrar las imagenes
  {
    console.log( "onShowImages = " + index )

    setTheIndex( index )
    setShowTheImages(true)
  }
  

  function setActivado( index )
  {

    console.log( "setActivado = " + index )

    //setData( temp )

    var url = "setsolicitado/?id=" + data[index].idmsgseg ;

    console.log( "url para setsolictado = " + url )

    ApiCallGet( 'setsolicitado/' + data[index].idmsgseg )
    .then((response) => {

        console.log( "set solcicitado = " + response )

        if ( response === "ok" ) // Debe ser en minusculas
        {
            back() ; // Llamamos a limpiar regresarnos a la opcion anterior
            alert( "Se ha marcado como solicitado")
        }

    })
    .catch((error) => {
        // handle error
        console.log( "error = " + error )
        alert( "Error al marcar como solicitado")
    })

    


  }

  function getList( index )
  {
    return(
      <>
        <td>
          <ShowCard data={data[index]} setNumFolio={setNumFolio} 
            setActivado={setActivado} index={index} showImages={onShowImages}/>
        </td>
      </>
    )
  }

  return (
    <div>
        <div className='row mt-5'>
        <div className='col-12'>
        <table className="table table-striped w-100" style={{margin:'auto'}} >
           
                <thead >
                    <tr >
                        <th scope="col">Ruta</th>
                    </tr>
                </thead>
                <tbody>

                { !showTheImages && (

                
                    data.map( (item, index) => {
                        return  (<tr>
                            {getList(index)}
                        </tr> );
                    })
                            
                        
                    )
                }

                {
                  showTheImages && ( 
                    <ShowImages folioSisact={myData[theIndex].folioSisact} arrIndex={myData[theIndex].numPhotos} usuario={usuario}></ShowImages>
                  )

                }
            </tbody>
        </table>
      </div>
      </div>
    </div>
  )
}
