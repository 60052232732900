import React , { useState , useEffect } from 'react'


import { confirmAlert } from 'react-confirm-alert'; // Import

import { ApiCallGet } from '../ApiCall/ApiCall'

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import './cardstyle.css'

 export default function ShowCard ( { data , setNumFolio , index , setActivado , showImages }) { // Data trae solo una tarjeta

  const [letrero,setLetrero] = useState( "Subir Imagenes")

  function onEntregado( index ) { // Lo hacemos con la estructura tradicional
    confirmAlert({
      title: 'Confirmal',
      message: 'Esta seguro de ENTREGADO',
      buttons: [
        {
          label: 'Si',
          onClick: () =>  setEntregado(index)  //alert('Click Si ' + data.idmsgseg )
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
    });
  };

  function setEntregado( index )
  {
    //setActivado( index )
    ApiCallGet( 'setentregado/' +  data.idmsgseg )
    .then( (response) => {
      console.log( "response = " + response )

      if ( response === "ok" )
      {
        alert( "Se actualizo correctamente a ENTREGADO" )
      }
      else{
        alert( "No se pudo actualizar a ENTREGADO" )
      }
      
    })
    .catch( (error) => {
      console.log( error )
      alert( "No se pudo actualizar a ENTREGADO" )
    })
  }


  function setFolioActivado() 
  {
    if ( letrero === "Subir Imagenes" )
    {
      setNumFolio( index )
    }
    else{
      setActivado( index )
    }

  }

  useEffect(()=>{

    console.log( "data.estatus = " + data.estatus )

    if ( data.estatus === null ||  data.estatus.trim() === '' )
    {
      console.log( "vamos a cambiar letrero " + data.celular )
      setLetrero( "Solicitar")
    }

  },[data])

  return (
    <div className='card'  style={{backgroundColor : 'lightblue' , fontWeight :'bold'}} >
        <p>Celular : {data.celular}</p>
        <p>Cliente : {data.cliente}</p>
        <p>Fuerza de Venta : {data.fv}</p>
        <p>Estatus : {data.estatus}</p>
        <p>Entregado : {data.entregado===1 ?'Si' : 'No'}</p>
        <p>Fotos Subidas : {data.numPhotos.length}</p>
        <p>Fecha : {data.fecha}</p>
        <p>FolioCrm : {data.foliocita}</p>
        <p>Observaciones : {data.observaciones}</p>

        <div className='row'>

          {
            (data.estatus ===  "ACTIVADO" || data.estatus === "RECHAZADO"  ||  data.estatus === null) &&(          
              <div className='col-3' >
                <button className="btn btn-primary mt-3 button-3" onClick={(val) =>setFolioActivado(index)} >{letrero}</button>
              </div>

            )
          }


          <div className='col-1'>

          </div>

          {
            data.numPhotos.length > 0 && (
              <>
                <div className='col-3'>
                  <button className="btn btn-primary mt-3 button-3" onClick={()=>showImages(index)}>Ver imagenes</button>
                </div>

                <div className='col-1'>
                </div>

              {
                ( data.entregado !== 1 && data.estatus === 'ACTIVADO' ) && (
                <div className='col-3'>
                  <button className="btn btn-primary mt-3 button-3" onClick={()=>onEntregado(index)}>Entregado</button>
                </div>
                )
              }



              </>
            )
          }


        </div>

        
        

    </div>
  )
}

