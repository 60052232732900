import React , { useState , useEffect} from 'react'

import { ApiCallPost } from '../ApiCall/ApiCall';


// showNormal = Solicitamos regresar al estado normal
export default function LoadPhoto( { usuario , id  , index  , myData } ) {

  const [theValues,setTheValues] = useState( [] )
  const [data,setData] = useState( myData )

  //setData( myData )

  useEffect(() => {
    console.log( "myDate en LoadPhoto = " , data )
  }, [data])


  useEffect(() => {
    console.log("Index =  ", index)
  

  },[index])

  useEffect (() => {

    if ( theValues == null )
    {
      return ;
    }

    console.log("theValues = ", theValues)

    console.log("theValues.length = ", theValues.length)
  },[theValues])

  


    // sol1824M  , 040083


    // 013785 = usuario  // 638416 = folio

    function getDisplay( index )
    {
      if ( index == null )
      {
        return ""
      }

      return (
        <>
          Celular : {data[index].celular} FolioSisact : {data[index].folioSisact}
        </>
      )

    }
    
    function preImage( file )
    {
        console.log( "preImage") ;


        //const file = event.target.files[0];

        //console.log( "file = " + file.name)

        var valArray = []

        for( var i = 0 ; i < file.length ; i++ )
        {
          console.log( "file = " + file[i].name)
       

          const reader = new FileReader(); 

          reader.readAsDataURL(file[i])

          reader.onloadend = function() {
            const base64 = reader.result;

            //console.log( "base64 = " + base64 ) ; // Aqui tenemos la base 64 e la foto

            console.log( "id = " , data[index].idmsgseg )

            //return ;


            var values = {
              nSec : 1 ,
              idMsgSeg : data[index].idmsgseg,
                image: base64 === "{}" ? null : base64 , // new Buffer(base64.substr( 22 ), 'base64'),
                longitud : "0",
                latitud : "0" ,
                //cPath : "" ,
                dTimeStamp: new Date()
                } ;

                

                valArray.push( values)
                console.log("values = ", valArray.length)
              

                

          }
        };

        console.log("valArray agregando = ", valArray.length )

        setTheValues( valArray ) // Le vamos agregando el nuevo elemento
    }

    function uploadImages(  )
    {

        if ( theValues == null )
        {
          alert("No hay imagen seleccionada")
          return
        }
      
        console.log("uploadImages " + theValues.length  )

        //return ;

        for( var i = 0 ; i < theValues.length ; i++ )
        {
          console.log( "Solicitando carga de " + i )
          uploadImage( i )
        }

        setTheValues( null )  

        alert("Imagenes subidas correctamente")
    
    }

    function uploadImage( id ){


      
        if ( theValues == null )
          {
            alert("No hay imagen seleccionada")
            return("false")
          }
    

      

        ApiCallPost( "evidence" , theValues[id] )
        .then((result) => {
          console.log("Result = " + result)

          if ( result === "ok" )
          {
            //alert("Imagen subida correctamente")

            console.log("Se subio imagen  = ", id)
          }

          //setTheValues( null )

          return("ok")
        
        })
        .catch((err) => {
          console.log(err)
          //setTheValues( null )

          return ("false")
        })
      
    }

  return (
    <>
      <div>Subir Foto(s) {getDisplay(index)}  </div>
        <input type='file' className="btn btn-primary mt-3 button-3"
          onChange={(event=>preImage(event.target.files))} multiple={true} accept='image/*'></input>
        <button className="btn btn-primary mt-3 button-3" onClick={uploadImages}>Subir imagen</button>
    </>
    
  )
}
