import React , { useState , useEffect } from 'react'

import { ApiCallGet } from '../ApiCall/ApiCall'

import { UncachedImage } from "react-uncached-image";



export default function ShowImages( { arrIndex  , usuario , folioSisact}) {

    const [images,setImages] = useState([]) // de inicio el arreglo vacio
    const [isLoaded,setIsLoaded] = useState(false)

    useEffect(() => {
        console.log( "Images.length *******= " + images.length )
    } , [images])

    useEffect(()=> {
        console.log( "ShowImages" , arrIndex.length  ) // Se llama cada vez que cambia el indez

        

        if ( isLoaded ) return ;

        var array = [] // Creamos un arreglo vacio

        if ( arrIndex.length === 0 ) return ;

            console.log( "usuario = " + usuario )

            ApiCallGet( 'getimagebyindex/' + buildList() + "/" + usuario ) // Aqui ya pasamos el usuario
            .then( (response) => {
                console.log ( "Response = " , response[1] )

                
                array.push( response )

                console.log( "Agregando imagen " , array.length )

                setImages( response )


                
            })
            .catch( (error) => {
                console.log( error )
            })

    } , [arrIndex]) // Se ejecuta cada vez que cambia el arreglo

    function buildList()
    {
        var array = ''

        for( var i = 0 ; i < arrIndex.length ; i++ )
        {
            array += arrIndex[i].idevidencias + ( i < arrIndex.length -1 ? ',' : '')
        }

        return array
    }

/*
    function getBuffer( image  , index )
    {

        console.log( "index = " + index )

        console.log( "TheImage = " , image )

        if ( image.image === null ) return null

        console.log( "Image = " , image.image )

        const reader = new FileReader();
        reader.readAsArrayBuffer(image.image);
        reader.onload = () => {
            const buffer = reader.result;
            return buffer;
        };
    }
*/    

  return (
    <div>
        {
            images.map( (image , index ) => {
                return (
                    <>
                    <p>Imagen FolioSisact={folioSisact}</p>
                    {
                        image.image !== 'null' && (
                            <>
                            <UncachedImage style={{width:'400px'}} src={  image}  alt='Imagen'/>
                            {/*<img style={{width:'400px'}} src={ 'http://localhost:3050/' + image}  alt='Imagen'/>*/}
                            </>
                        ) 
                    }

                    {
                        image.image === 'null' && (
                            <p>No hay imagen</p>
                        )
                    }
                    


                    {/*<img src={`data:image/jpeg;base64,${Buffer.from(image , 'base64')}`}  alt='Imagen'/>*/}

                    {/* base64ToBlob(base64String, 'image/jpeg'); */}
                    {/*<img src=`data:image/jpeg;base64,${image}` alt="Imagen" />*/} 
                    
                    </>
                )
            })
        }
    </div>
  )
}
